import { COMPANY_PERMISSION_OPTIONS } from "../FormulatorAdminProfilePermissionsEditor/const";
import { ACCOUNT_LIMITED_POLICY_MESSAGE } from '../../data/messages';

const INPUT = 'INPUT';
const BUTTON = 'BUTTON';
const TEXTAREA = 'TEXTAREA';

export const TEMPORARY_FIELDS = {
    containesImpurities: true,
}

export const LIMITED_ACC_MESSAGES = {
    TOXIN_GROUPS:
        ACCOUNT_LIMITED_POLICY_MESSAGE,
    REPORTS:
        'Your account is limited to certain policies. This type of report is not available for basic accounts. ' +
        'If you would like to be able to create these reports, please upgrade your access.',
};

export const LOCKED_PRODUCT_MESSAGE =
    'Product is locked for editing. Please remove lock to perform changes.';

export const SUCCESSFULLY_CREATED_FORMULA = 'Hang on! This formula is saving.';

export const FORMULA_NUM_WAS_UPDATED_AFTER_CREATION = 'If the automated formula number already exists in your library, the next available number will be applied upon saving.';

export const RM_IS_NOT_IN_RIGHT_STATUS =
  'This Raw Material is in a status which cannot be added to the formula. Please update the Raw Material status in order to add this Raw Material to the formula.';

export const REPORT_WARNING_TYPES = 'Reports are not yet available for household product types';

export const PRODUCT_NAME_LENGTH_LIMIT = 220;

export const PRODUCT_BRAND_LENGTH_LIMIT = 100;

export const LINKS = [
    {
        name: 'Formula Info',
        endPoint: '/formula-info',
        pageType: 'formula-info',
        id: 'formula',
        permission: '',
    },
    {
        name: 'Ingredients',
        endPoint: '/raw-material',
        pageType: 'raw-material',
        id: 'material',
        permission: '',
    },
    {
        name: 'Formula Notes',
        endPoint: '/notes',
        pageType: 'notes',
        id: 'notes',
        permission: COMPANY_PERMISSION_OPTIONS.FORMULA_NOTES,
    },
    {
        name: 'Actives',
        endPoint: '/actives',
        pageType: 'actives',
        id: 'actives',
        permission: COMPANY_PERMISSION_OPTIONS.SHOW_BENEFITS_BLOCK,
    },
    {
        name: 'Regulatory Info',
        endPoint: '/regulatory-info',
        pageType: 'regulatory-info',
        id: 'reg',
        permission: '',
    },
    {
        name: 'Formula Documents',
        endPoint: '/documents',
        pageType: 'documents',
        id: 'docs',
        permission: '',
    },
];

export const LINKS_NEW = [
    {
        name: 'Formula Info',
        endPoint: '/formula-info',
        pageType: 'formula-info',
        id: 'formula',
        permission: '',
    },
    {
        name: 'Ingredients',
        endPoint: '/raw-material',
        pageType: 'raw-material',
        id: 'material',
        permission: '',
    },
    {
        name: 'Phases',
        endPoint: '/phases',
        pageType: 'phases',
        id: 'phase',
        permission: COMPANY_PERMISSION_OPTIONS.FORMULA_PHASE,
    },
    {
        name: 'Formula Notes',
        endPoint: '/notes',
        pageType: 'notes',
        id: 'notes',
        permission: COMPANY_PERMISSION_OPTIONS.FORMULA_NOTES,
    },
    {
        name: 'Actives',
        endPoint: '/actives',
        pageType: 'actives',
        id: 'actives',
        permission: COMPANY_PERMISSION_OPTIONS.SHOW_BENEFITS_BLOCK,
    },
    {
        name: 'Regulatory Info',
        endPoint: '/regulatory-info',
        pageType: 'regulatory-info',
        id: 'reg',
        permission: '',
    },
    {
        name: 'Formula Documents',
        endPoint: '/documents',
        pageType: 'documents',
        id: 'docs',
        permission: '',
    },
];


export const DOCUMENT_TYPES = {
    COMPOUND_CHILD: 'compound-child',
    COMPOUND_ORIGIN: 'compound-origin',
    PARAMS: 'params',
    COMPOUND_PARAMS: 'compound-params',
}

export const SPECIFICATIONS_MENU = [{
    name: 'Specs',
    endPoint: '/specs',
    pageType: 'specs',
    id: 'specs',
    permission: '',
}]

export const IMPURITY_TAG_OPTIONS = [{
    value: 'impurity',
    label: 'Impurity'
}];

export const INCIDENTAL_TAG_OPTIONS = [{
    value: 'incidental',
    label: 'Incidental'
}];

export const RESTRICTION_TYPES = {
    CHEMICAL: 'Chemical',
    CONSUMER: 'Consumer',
    GEOGRAPHIC: 'Geographic',
    RETAIL: 'Retail',
    POLICY: 'Policy',
    PRESETS: 'Presets',
}

export const SELECT_ALL_REGULATION_OPTIONS = [
    { value: 'all', label: 'Select All Regulations' },
];

export const SELECT_ALL_PRESETS_OPTIONS = [
    { value: 'all', label: 'Select All Presets' },
];

export const DEFAULT_EDIT_FORMULA_TABLE_LAYOUT = {
    concentration: true,
    local_concentration: true,
    rm: true,
    rm_status: true,
    rm_manufacturer: true,
    warning_flags: true,
    formula_functions: true,
    formula_tags: true,
    phases: true,
    biodegradability: true,
    natural_content: true,
    natural_origin: true,
    organic_content: true,
    organic_origin: true,
    costs: true,
}

export const EDIT_FORMULA_TABLE_COLUMN_SWITCHER_NAMES = {
    concentration: 'Total Concentration',
    local_concentration: 'Local Concentration',
    rm: "RM Reference #",
    rm_status: 'RM Status',
    rm_manufacturer: 'RM Manufacturer',
    warning_flags: 'Warning Flags',
    formula_functions: 'Functions',
    formula_tags: 'Type',
    phases: 'Phase',
    biodegradability: 'Biodegradability',
    natural_content: 'Natural Content',
    natural_origin: 'Natural Origin',
    organic_content: 'Organic Content',
    organic_origin: 'Organic Origin',
    costs: 'Cost/Formula Cost',
    actions: 'Actions',
}

export const POSITION_PARAM = 'position';
export const FIXED_POSITION_PARAM = 'view_position';
export const TEMP_POSITION_PARAM = 'temp_position';
export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
export const SF = 'SF';
export const RM = 'RM';
export const TRACKING_SF_TAGS_ACTIONS = [INPUT, BUTTON, TEXTAREA];
export const VARIATION_SAVE_ERROR = 'You must first save the product variation in order to add ingredients.';
